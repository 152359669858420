<template>
    <div class="page">
        <titlebar :name="name" />
        <div class="list_title">
            <div>创建时间</div>
            <div>更新时间</div>
            <div>分类名称</div>
            <div>操作</div>
        </div>
        <!-- 提现记录表 -->
        <div class="box_index">
            <div
                class="list_index"
                v-for="(item, index) in list"
                :key="index"
            >
                <div>{{ item.create_time }}</div>
                <div>{{ item.update_time }}</div>
                <div>{{ item.name }}</div>
                <div>
                    <template v-if="shop.user_model == 1">
                        <van-icon name="edit" size="3rem" @click="edit(item)" />
                        <van-icon name="delete-o" size="3rem" color="#ee0a24" @click="openDialog(item)" style="margin-left: 6rem" />
                    </template>
                    <template v-else>无权操作</template>
                </div>
            </div>
        </div>
        <van-col span="24">
            <div class="bottom-con" style="padding: 3rem;">
                <van-pagination
                    v-model="page"
                    :total-items="total"
                    :items-per-page="limit"
                    @change="pageChange"
                    force-ellipses
                    mode="simple"
                    style="width: 30%;"
                />
                <van-button color="#1588F5" @click="add" v-if="shop.user_model == 1" icon="add">新增分类</van-button>
            </div>
        </van-col>

        <van-popup v-model="showdet" @close="category_name = ''">
            <div class="popups">
                <div class="system_title">
                    <div>{{ popTitle }}分类</div>
                    <img src="https://dzb.api.casher.kissneck.net/202106/mdsy/close.png" @click="showdet = false" />
                </div>
                <!-- 其他支付 -->
                <div class="detail">
                    <div class="flex flex-ac nowrap mg-bt30">
                        <div>名称</div>
                        <input type="text" v-model="category_name" ref="modalInput" autofocus />
                    </div>
                    <div class="flex flex-ac nowrap">
                        <div>排序</div>
                        <input type="number" v-model="sort" placeholder="数字越大越靠前" />
                    </div>
                </div>
                 <div class="but" @click="confirmDet">确定</div>
            </div>
        </van-popup>

        <popupOfDialog
            v-if="showDialog"
            :type="dialogType"
            :title="dialogTitle"
            :message="dialogMsg"
            :data="dialogData"
            @closePopDialog="closePopDialog"
            @confirmPopDialog="confirmPopDialog"
        ></popupOfDialog>
    </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import {Toast} from "vant";
import { mapGetters } from 'vuex';
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
    name: "ShopCategory",
    components: {titlebar, popupOfDialog},
    async created() {
        this.name = this.$route.query.name; //拿到传过来的标题
        await this.getGoodsType();
    },
    data() {
        return {
            name: '',
            list: [], // 商品分类
            limit: 8,
            page: 1,
            total: 0,
            showdet: false,
            category_name: '',
            popTitle: '',
            editId: '',
            sort: '',
            showDialog: false,
            dialogTitle: "",
            dialogMsg: "",
            dialogType: "",
            dialogData: {},
        }
    },
    computed: {
        ...mapGetters({shop: 'shop'})
    },
    methods: {
        async getGoodsType() {
            let res = await this.$api.goodsType({
                shop_id: localStorage.shop_id,
                limit: this.limit,
                page: this.page
            });
            if (res.code == 1) {
                this.list = res.data.list;
                this.total = res.data.count;
            }
        },
        pageChange(e) {
            this.page = e;
            this.getGoodsType();
        },
        add() {
            this.popTitle = '新增';
            this.category_name = '';
            this.sort = '';
            this.showdet = true;
        },
        edit(item) {
            this.popTitle = '编辑';
            this.editId = item.id;
            this.category_name = item.name;
            this.sort = item.sort || '';
            this.showdet = true;
        },
        confirmPopDialog(data) {
            this.del(data.data);
            this.closePopDialog();
        },
        closePopDialog(data) {
            this.showDialog = false;
        },
        openDialog(item) {
            this.dialogTitle = "提示";
            this.dialogMsg = `是否确认删除？`;
            this.dialogType = '4';
            this.dialogData = item;
            this.showDialog = true;
        },
        async del(item) {
            let res = await this.$api.delShopCategory({
                category_id: item.id
            });
            if (res.code == 1) {
                Toast('删除成功');
                await this.getGoodsType();
            }
        },
        async confirmDet() {
            if (this.category_name) {
                if (this.popTitle == '新增') {
                    let res = await this.$api.addShopCategory({
                        category_name: this.category_name,
                        shop_id: localStorage.shop_id,
                        sort: this.sort,
                    })
                    if (res.code == 1) {
                        Toast('新增成功');
                        this.showdet = false;
                        await this.getGoodsType();
                    }
                } else {
                    let res = await this.$api.editShopCategory({
                        category_name: this.category_name,
                        category_id: this.editId,
                        shop_id: localStorage.shop_id,
                        sort: this.sort,
                    })
                    if (res.code == 1) {
                        Toast('编辑成功');
                        this.showdet = false;
                        await this.getGoodsType();
                    }
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.bottom-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
    background: #fff;
}
// /deep/.van-pagination__item--active {
//     color: #fff !important;
//     background-color: #1588F5 !important;
// }
// /deep/.van-pagination__item {
//     color: #1588F5;
// }
.list_title {
    height: 8rem;
    background: #f8f8f8;
    font-size: 2.6rem;
    font-weight: 400;
    color: #333333;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    align-items: center;
}
.box_index {
    // height: 40rem;
    flex: 1;
    overflow-y: scroll;
}
.box_index::-webkit-scrollbar {
    display: none;
}
.list_index {
    height: 8.5rem;
    font-size: 2.6rem;
    font-weight: 400;
    color: #333333;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    align-items: center;
    border-bottom: 0.1rem solid #e5e5e5;
    background: #fff;
}
.popups {
    width: 102.9rem;
    height: 52.7rem;
    background: #ffffff;
    text-align: -webkit-center;

    .system_title {
        display: flex;
        align-items: center;
        //justify-content: flex-end;
        //margin: 0 4.6rem 3.1rem 4.6rem;
        height: 12.5rem;
        border-bottom: 0.1rem solid #e5e5e5;
        font-size: 3.6rem;
        font-weight: 400;
        color: #333333;
        position: relative;
        >:first-child {
            flex: 1;
        }
        img {
            width: 4.3rem;
            position: absolute;
            right: 4rem;
            // height: 4.3rem;
            // text-align: end;
            //margin-left: 29.4rem;
        }
    }

    .detail {
        margin: 6rem 17.6rem 6rem 18rem;
        text-align: left;
        font-size: 2.8rem;
        font-weight: bold;
        color: #333333;
        line-height: 4rem;

        input {
            width: 62.6rem;
            height: 7.1rem;
            background: #f8f8f8;
            border-radius: 0.8rem;
            margin: 0 0 0 5rem;
            padding-left: 6.3rem;
            font-size: 2.6rem;
            font-weight: 400;
            color: #666666;
            line-height: 3.7rem;
            flex: 1;
        }

        .detail_context {
            font-size: 3.6rem;
            color: #fe5600;
        }
    }

    .but {
        width: 27rem;
        height: 7rem;
        background: linear-gradient(270deg, #1588F5 0%, #1588F5 100%);
        font-size: 2.8rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.van-button--normal {
    font-size: 2.4rem;
    width: 42rem;
    height: 7rem;
    border-radius: 0.8rem;
}

</style>
